import './App.css';
import React, {useState} from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download'

function App() {
  const [file, setFile] = useState();
  const [downlodUrl, setDownloadUrl] = useState("")
  function handleChange(event) {
    setFile(event.target.files[0])
  }
  
  function handleSubmit(event) {
    event.preventDefault()
    const url = '/app/uploadFile';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
      setDownloadUrl(response.data.downlodUrl)
    });
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename);
      setDownloadUrl("")
    })
  }
   
 

  return (
    <div className="App">
        <form onSubmit={handleSubmit}>
        <div className="upload">
          <div className="upload-files">
          <header>
            <p>
            <i className="fa fa-cloud-upload" aria-hidden="true"></i>
            <span className="load">Upload & Convert</span>
            </p>
          </header>
          <div className="body" id="drop">
            <i className="fa fa-file-text-o pointer-none" aria-hidden="true"></i>
            <p className="pointer-none"><b>Select file to convert</b> </p>
            <p className="pointer-none"> <input type="file"  onChange={handleChange}/></p>
          </div>
          <div className='footerLinks'>
              <button type='submit'  className="importar">Upload File</button>
              { downlodUrl!=='' &&
              <p>
                <a href="#" onClick={()=>handleDownload(downlodUrl, 'products.csv')}>CSV IS READY Click To Download</a>
              </p>
              }
          </div>
          </div>
    
         
                    </div>  
          </form>
    </div>
  );
}

export default App;